<i18n src="@i18n/drone/drone_model.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
drone_model.field.section.general : "ข้อมูลของ Drone Model"

drone_model.field.image : "รูป Drone"

drone_model.field.name.help : "เป็นชื่อรุ่นจริง ๆ ห้ามซ้ำ เช่น TGD1X Rev.1"
drone_model.field.name.placeholder : "TGD1X Rev.1"

drone_model.field.display_name.help : "เป็นชื่อที่แสดงให้ลูกค้าดู โดยตัวไปจะเป็นชื่อรุ่นหลัก เช่น TGD1X"
drone_model.field.display_name.placeholder : "TGD1X"

drone_model.field.inspection_type.placeholder : "ระบุค่า"
drone_model.field.inspection_type.help : "แบบฟอร์มที่ใช้ตรวจสอบ Drone ระหว่างซ่อม (*** แก้ได้ตอน Draft เท่านั้น ***)"

drone_model.field.drone_name_prefix : "คำนำหน้าชื่อ Drone"
drone_model.field.drone_name_prefix.help : "คำนำหน้าชื่อสำหรับ Drone เช่น TGD1X018 ค่าจะเป็น TGD1X (*** แก้ได้ตอน Draft เท่านั้น ***)"
drone_model.field.drone_name_prefix.placeholder : "TGD1X"

drone_model.field.drone_type.help : "ประเภทการใช้งานสำหรับ Drone รุ่นนี้"
drone_model.field.firmware_set.help : "Drone รุ่นนี้มีการใช้งานกับ Firmware แบบไหน (*** แก้ได้ตอน Draft เท่านั้น ***)"
drone_model.field.firmware_set.placeholder : "เลือก Firmware Set"
drone_model.field.hgr_ref_code.help : "ชื่อเรียกอ้างอิงกับระบบ HGR เพื่อใช้ในการเชื่อมต่อข้อมูลระหว่าง 2 ระบบ (ติดต่อ HGR Team เพื่อขอ code)"
drone_model.field.hgr_ref_code.placeholder : "เช่น TGD1X หรือ TGD1XPLUS"
drone_model.field.release_date.help : "วันที่วางจำหน่าย หรือ ประกาศเปิดตัว"
drone_model.field.warranty_duration.help : "ระยะเวลารับประกันตัว Drone โดยทั่วไปสำหรับรุ่นนี้ (0 = ไม่รับประกัน)"
drone_model.field.warranty_flight_time.help : "นอกจากจะดูจากช่วงเวลารับประกัน (0 = ไม่คิดชั่วโมงบิน)"
drone_model.field.remark.placeholder : "หมายเหตุ สำหรับ Drone รุ่นนี้"
</i18n>

<template>
	<a-form
		layout="horizontal"
		:label-col="labelCol"
		:wrapper-col="wrapperCol"
		:form="formObj"
		@submit.prevent="handleSubmit">
		<div class="mylayout-left-right responsive">
			<div class="mylayout-left">
				<a-card :bordered="false" class="text-center">
					<ImageUploader
						:current-image-url="currentModelImageUrl"
						border-type="round"
						size="large"
						:max-width="1024"
						:max-height="1024"
						@error="handleFileUploadError"
						@change="hadleFileUploadChange">
						<DroneIcon class="anticon" />
					</ImageUploader>
					<div class="text-muted">{{$t('drone_model.field.image')}}</div>
				</a-card>
			</div>

			<div class="mylayout-right">
				<a-card :bordered="false">
					<div class="myform-section-title">
						{{$t('drone_model.field.section.general')}}
					</div>

					<a-form-item :label="$t('drone_model.field.name')"
						:extra="$t('drone_model.field.name.help')">
						<a-input
							v-decorator="[
								'name' ,
								{ rules: [
									{ required: true ,whitespace: true ,message: $tt('validate.required','drone_model.field.name') },
								] },
							]"
							type="text"
							:placeholder="$t('drone_model.field.name.placeholder')"/>
					</a-form-item>

					<a-form-item :label="$t('drone_model.field.display_name')"
						:extra="$t('drone_model.field.display_name.help')">
						<a-input
							v-decorator="[
								'displayName' ,
								{ rules: [
									{ required: true ,whitespace: true ,message: $tt('validate.required','drone_model.field.display_name') },
								] },
							]"
							type="text"
							:placeholder="$t('drone_model.field.display_name.placeholder')"/>

					</a-form-item>

					<a-form-item :label="$t('drone_model.field.drone_name_prefix')"
						:extra="$t('drone_model.field.drone_name_prefix.help')">
						<a-input
							v-show="canUpdateImportant"
							v-decorator="[
								'displayNamePrefix' ,
								{ rules: [
									{ required: true ,whitespace: true ,message: $tt('validate.required','drone_model.field.drone_name_prefix') },
								] },
							]"
							style="width:200px"
							:max-length="15"
							type="text"
							:placeholder="$t('drone_model.field.display_name.placeholder')">
							<span slot="addonAfter">0XX</span>
						</a-input>
						<span v-show="!canUpdateImportant" class="ant-form-text">
							<span class="text-secondary text-bold">{{currentNamePrefix}}</span>0XX
						</span>
					</a-form-item>

					<!-- <a-form-item :label="$t('drone_model.field.drone_type')"
						:extra="$t('drone_model.field.drone_type.help')">
						<a-radio-group
							v-decorator="[
								'drone_type' ,
								{
									rules: [
									{ required: true ,message: $tt('validate.required','drone_model.field.drone_type') },
									] ,
									'initialValue': 'spraying',
								},
							]">
							<a-radio value="spraying">{{$tenum('drone_type','spraying')}}</a-radio>
							<a-radio value="mapping">{{$tenum('drone_type','mapping')}}</a-radio>
						</a-radio-group>
					</a-form-item> -->
					<a-form-item :label="$t('drone_model.field.inspection_type')" :extra="$t('drone_model.field.inspection_type.help')">
						<a-select v-show="canUpdateImportant"
							v-decorator="[
								'inspectionType' ,
								{
									rules: [
										{ required: true,message: $tt('validate.required','drone_model.field.inspection_type') },
									],
								},
							]"
							style="width:200px"
							:get-popup-container="getPopupContainer"
							:placeholder="$t('drone_model.field.inspection_type.placeholder')">
							<a-select-option value="tgd1x">
								{{$tenum('inspection_type','tgd1x')}}
							</a-select-option>
						</a-select>
						<span v-show="!canUpdateImportant" class="ant-form-text">
							{{$tenum('inspection_type',currentInspectionType)}}
						</span>
					</a-form-item>

					<!-- <a-form-item :label="$t('drone_model.field.hgr_ref_code')" :extra="$t('drone_model.field.hgr_ref_code.help')">
						<a-input
							v-decorator="['hgr_ref_code']"
							style="width:250px"
							:placeholder="$t('drone_model.field.hgr_ref_code.placeholder')" />
					</a-form-item> -->

					<!-- <a-form-item :label="$t('drone_model.field.firmware_set')" :extra="$t('drone_model.field.firmware_set.help')">
						<a-select
							v-if="canUpdateFirmwareSet"
							v-decorator="[
								'firmware_set_id' ,
								{
									rules: [
										{ required: true,message: $tt('validate.required','drone_model.field.firmware_set') },
									],
								},
							]"
							style="width:200px"
							:get-popup-container="getPopupContainer"
							:placeholder="$t('drone_model.field.firmware_set.placeholder')">
							<a-select-option v-for="firmwareSet of firmwareSets" :key="firmwareSet.id"
								:value="firmwareSet.id">
								{{firmwareSet.name}}
							</a-select-option>
						</a-select>
						<span v-else class="ant-form-text">
							{{currentFirmwareSet | emptyCheck}}
						</span>
					</a-form-item> -->

					<a-form-item v-if="mode === 'update'" :label="$t('drone_model.field.status')">
						<span class="ant-form-text">
							{{$tenum('drone_model.status',droneModelStatus)}}
						</span>
					</a-form-item>

					<a-form-item :label="$t('drone_model.field.release_date')"
						:extra="$t('drone_model.field.release_date.help')">
						<MyDatePicker
							v-decorator="['releaseDate',
									{
										rules: [
											{ required: true ,whitespace: true ,message: $tt('validate.required','drone_model.field.release_date') },
										] ,
									}]"
							format="DD MMMM YYYY"/>
					</a-form-item>

					<a-form-item :label="$t('drone_model.field.warranty_duration')"
						:extra="$t('drone_model.field.warranty_duration.help')">
						<a-input-number
							v-decorator="['warrantyDuration',
									{
										rules: [
											{ required: true ,message: $tt('validate.required','drone_model.field.warranty_duration') },
										] ,
									}]"
							:min="0" :step="1"
							:precision="0"
							class="myinput-number"
							length="30">
						</a-input-number>
						<span class="ant-form-text">
							{{$t('common.day')}}
						</span>
					</a-form-item>

					<a-form-item :label="$t('drone_model.field.warranty_flight_time')"
						:extra="$t('drone_model.field.warranty_flight_time.help')">
						<a-input-number
							v-decorator="['warrantyFlightTime',
									{
										rules: [
											{ required: true ,message: $tt('validate.required','drone_model.field.warranty_flight_time') },
										] ,
									}]"
							:min="0" :step="1"
							:precision="0"
							class="myinput-number"
							length="30">
						</a-input-number>
						<span class="ant-form-text">
							{{$t('common.hour')}}
						</span>
					</a-form-item>

					<hr/>
					<a-form-item :label="$t('drone_model.field.remark')">
						<a-textarea
							v-decorator="['description']"
							:auto-size="{ minRows: 3,maxRows: 8 }"
							:placeholder="$t('drone_model.field.remark.placeholder')">
						</a-textarea>
					</a-form-item>

					<a-form-item v-bind="wrapperOnlyLayout">
						<slot name="submitSlot" :formLoading="loading">
							<a-button
								:loading="loading"
								type="info"
								html-type="submit"
								class="btn-submit"
								size="large">
								{{$t('common.save')}}
							</a-button>
						</slot>
					</a-form-item>
				</a-card>
			</div>
		</div>
	</a-form>
</template>

<script>
import ImageUploader from '@components/input/ImageUploader.vue'
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import DroneIcon from "@components/icon/DroneIcon.vue"
import MyDatePicker from '@components/input/MyDatePicker.vue'
import { updateFieldsValue } from '@utils/formUtil'
import ApiError from '@utils/errors/ApiError'
import {InputNumber,Select} from "ant-design-vue"
import {mapState} from "vuex"
import PopupMixin from "@mixins/PopupMixin.vue"
import {getStatusActiveByDeletedAt} from "@utils/commonUtil"
export default {
	components : {
		ImageUploader,MyDatePicker ,
		// "a-radio-group" : Radio.Group , "a-radio" : Radio,
		"a-select" : Select , "a-select-option" : Select.Option,
		"a-input-number" : InputNumber,DroneIcon

	} ,
	mixins : [HasAntdFormMixin,PopupMixin] ,
	props : {
		mode : {
			type : String ,
			default : 'update'
		} ,
		loading : {
			type : Boolean,
			default : false,
		}
	} ,
	data() {
		return {
			currentModelStatus : null,
			currentModelImageUrl : null,
			currentNamePrefix : null,
			currentFirmwareSet : null,
			currentInspectionType : null,
			imageUploadData : {} ,
		}
	} ,
	computed : {
		...mapState('drone',['firmwareSets']) ,
		canUpdateImportant() {
			return this.mode == 'create' || (this.currentModelStatus === 'draft');
		} ,
		canUpdateFirmwareSet() {
			return this.mode == 'create' || (this.currentModelStatus === 'draft');
		},
		droneModelStatus(){
			return getStatusActiveByDeletedAt(this.model?.deletedAt)
		},
	} ,

	methods : {
		updateModel() {
			updateFieldsValue(this.formObj,this.model)
		} ,
		handleFileUploadError(error) {
			this.$message.error(ApiError.getDisplayErrorMessage(this,error));
		} ,
		hadleFileUploadChange(value) {
			this.imageUploadData = value;
		} ,
		handleSubmit() {
			this.formObj.validateFields((err,values) => {
				if (!err) {
					const formData = {
						...values ,
						'imageFileBase64' : this.imageUploadData ,
					};
					this.$emit('submit',formData)
				} else {
					this.displayValidateErrorMessage()
				}
			})
		} ,
		updateModelForm(model) {
			this.currentModelImageUrl = model.imageUrl
			this.currentNamePrefix = model.displayNamePrefix
			this.currentModelStatus = model.status
			this.currentFirmwareSet = model.firmwareSet ? model.firmwareSet.name : null
			this.currentInspectionType = model.inspectionType
			this.$nextTick(()=>{
				// Make firmware set visible before
				updateFieldsValue(this.formObj,model)
			})

		} ,
		formErrors(error) {
			this.formSubmitErrors(error)
		}
	}
}
</script>
